import React from 'react';
import {
  Added,
  Fixed,
  Improved,
  Link,
  List,
  PageWithSubNav,
  Paragraph,
  PlatformHeader,
  Removed,
} from 'docComponents';
import styles from '../../index.module.scss';

const IndexPage = () => {
  return (
    <PageWithSubNav
      pageType="design"
      title="April 2018"
      subnav="release-notes">
      <div id="April2018" className={styles.release}>
        <Paragraph className="uni-margin--one--vert">
          Until every right thing is easy, we’re going to keep pushing the
          bits—that includes additional writing guidelines. Check below for much
          needed updates to our button and select components, prettier inputs,
          macOS love and all kinds of Sketch cleanup.
        </Paragraph>

        <div className={styles.releaseInfo}>
          <div className={styles.platform}>
            <PlatformHeader libName="Docs" />

            <div className={styles.changesList}>
              <Added>
                <Link href="/words/writing-guidelines/mobile-notifications">
                  Mobile notification
                </Link>{' '}
                dos and don’ts to save everyone from information overload.
              </Added>
              <Added>
                <Link href="/words/writing-guidelines/transactional-emails">
                  Transactional email
                </Link>{' '}
                tips worthy of any inbox.
              </Added>
              <Improved>Animation and site performance.</Improved>
            </div>
          </div>

          <div className={styles.platform}>
            <PlatformHeader
              libName="Components"
              version="3.2.1"
              versionUrl="https://github.com/hudl/uniform-ui-components/releases/tag/v3.2.1"
            />

            <div className={styles.changesList}>
              <Added>
                Translation props for <code>cancelText</code> on{' '}
                <Link
                  href="/components/modal/code?web#cancelText"
                  type="article">
                  modal
                </Link>{' '}
                and <code>noMatchingResultsText</code> in{' '}
                <Link
                  href="/components/selects/lookup-select/code?web#noMatchingResultsText"
                  type="article">
                  lookup select
                </Link>
                .
              </Added>
              <Improved>
                <Link
                  href="/components/buttons/button/design#Usage"
                  type="article">
                  Button status
                </Link>{' '}
                with feedback icons and a new loading spinner.
              </Improved>
              <Fixed>
                Disabled and error states for{' '}
                <Link
                  href="/components/selects/single-select"
                  type="article"
                  isDesignCodeLink>
                  single select
                </Link>{' '}
                and{' '}
                <Link
                  href="/components/selects/lookup-select"
                  type="article"
                  isDesignCodeLink>
                  lookup select
                </Link>
                . Thanks, Rees and Amanda!
              </Fixed>
            </div>
          </div>

          <div className={styles.platform}>
            <PlatformHeader
              libName="CSS"
              version="1.0.4"
              versionUrl="https://github.com/hudl/uniform-ui-css/releases/tag/v1.0.4"
            />

            <div className={styles.changesList}>
              <Improved>
                {' '}
                <Link
                  href="/components/forms/input"
                  type="article"
                  isDesignCodeLink>
                  Input
                </Link>{' '}
                style in a number of ways. Most notably:
                <List>
                  <li>A tightened border radius</li>
                  <li>Level 0 background color</li>
                  <li>
                    Updated label and help text spacing for both standard and
                    compact densities
                  </li>
                </List>
              </Improved>
              <Fixed>
                Checked state for focused{' '}
                <Link
                  href="/components/forms/checkbox"
                  type="article"
                  isDesignCodeLink>
                  checkboxes
                </Link>{' '}
                and{' '}
                <Link
                  href="/components/forms/radio"
                  type="article"
                  isDesignCodeLink>
                  radios
                </Link>
                .
              </Fixed>
            </div>
          </div>

          <div className={styles.platform}>
            <PlatformHeader
              libName="macOS"
              version="1.1.0"
              versionUrl="https://github.com/hudl/macos-uniform-ui/releases/tag/v1.1.0"
            />

            <div className={styles.changesList}>
              <Added>
                <Link href="/guidelines/colors/code" type="article">
                  Color
                </Link>{' '}
                variables.
              </Added>
              <Added>
                <Link href="/components/icon/code?other" type="article">
                  Icons
                </Link>
                .
              </Added>
            </div>
          </div>

          <div className={styles.platform}>
            <PlatformHeader libName="Sketch Library" />

            <div className={styles.changesList}>
              <Added>Desktop, tablet and phone web navigation symbols.</Added>
              <Improved>
                Button status to display feedback icons and a new spinner.
              </Improved>
              <Improved>Spacing and color for inputs.</Improved>
              <Fixed>
                Help text to display <em>all</em> text.
              </Fixed>
              <Removed>The block button variant.</Removed>
              <Removed>All outline icons.</Removed>
            </div>
          </div>
        </div>
      </div>
    </PageWithSubNav>
  );
};

export default IndexPage;
